import React, { useState, useCallback } from 'react';
import FAQ from '../components/FAQ';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import '../styles/tokenization.scss';

const CardTokenization = () => {
  // const [tokenizationWorks, settokenizationWorks] = useState(0);
  const [OpenVideoModal, setOpenVideoModal] = useState(false);

  // const tokenizationSlideClick = useCallback((value) => () => {
  //   settokenizationWorks((state) => (state === value ? null : value));
  // });

  const OpenVideoModalToggle = useCallback(() => {
    setOpenVideoModal((state) => !state);
    document.body.classList.toggle('overflow');
  }, []);
  return (
    <Layout lightHeader>
      <SEO
        title="Token Vault, Card Tokenization solution | Cashfree Payments"
        description="Offer repeat customers the option to save their cards on your website or app. Process your customers’ saved cards securely and stay RBI compliant. Signup today."
        url="https://www.cashfree.com/card-tokenization"
        keywords={[
          'tokenization',
          'card tokenization',
          'payment tokenization',
          'credit card tokenization',
          'tokenization meaning',
          'payment card tokenization',
          'payment tokenization service providers',
          'token based authentication',
          'token authentication',
          'tokenized transaction',
          'tokenization payment meaning',
          'api token authentication',
          'Token Vault ',
        ]}
      />
      <section className="tokenization-hero left-text-hero">
        <div className="small-container">
          <div className="row row-no-margin flex-wrap">
            <div className="col col-7">
              <div className="hero-content">
                <h1 style={{ color: '#FFBE0C' }}>Token Vault </h1>

                <h4 className="white" style={{ maxWidth: 465 }}>
                  Card Tokenization Solution
                </h4>
                <p className="white" style={{ maxWidth: 365 }}>
                  Give your customers the option to save cards on your website
                  or app and process card payments securely while being RBI
                  compliant
                </p>

                <p className="white">Supports all major card types:</p>
                <ul className="check-list-round row row-no-margin justify-flex-start">
                  {[
                    {
                      id: 0,
                      listItem: 'Credit cards',
                    },
                    {
                      id: 1,
                      listItem: 'Debit cards',
                    },
                    {
                      id: 2,
                      listItem: 'Prepaid cards',
                    },
                    {
                      id: 3,
                      listItem: 'Corporate cards',
                    },
                  ].map(({ id, listItem }) => (
                    <li key={id}>
                      <span
                        style={{ backgroundColor: 'rgba(255,255,255,0.2)' }}>
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <h3>{listItem}</h3>
                    </li>
                  ))}
                </ul>

                <a
                  className="button button-green"
                  href="https://www.cashfree.com/contact-sales?source-action=tokenvault&action=Contact%20Sales&button-id=ContactSales_Hero"
                  style={{ margin: '5px' }}>
                  Contact Sales <span className="chevron" />
                </a>
              </div>
            </div>
            <div className="col col-5 center">
              <div className="token-illustration">
                <img src="/img/token-vault/token-vault-hero.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="small-container">
          <div className="center">
            <h2 style={{ marginBottom: 25 }}>What is card tokenization?</h2>
            <div style={{ maxWidth: 690, margin: '0 auto' }}>
              <p className="p-gap">
                As per RBI guidelines, with effect from{' '}
                <span className="bold">1st July 2022</span>, neither businesses
                nor payment aggregators can save customer card details on their
                platforms. The card details can only be saved by the card
                networks or issuing banks.
              </p>

              <p className="p-gap">
                Card tokenization is the process of replacing sensitive card
                information like card number, card expiry with a
                cryptographically generated random string, referred to as the
                card token.
              </p>

              <p className="p-gap">
                Once a card is tokenized, the generated card token can be used
                for processing payments as a substitute to card details, thus
                eliminating risk of loss of sensitive card information while
                making card payments.
              </p>
            </div>
            <div className="tokenization-works">
              <img src="/img/token-vault/tokenization-1.png" alt="" />
            </div>

            <a
              className="button button-green"
              href="https://www.cashfree.com/contact-sales?source-action=tokenvault&action=Contact%20Sales&button-id=ContactSales_WhatisCardTokenization"
              style={{ margin: '5px' }}>
              Try Token Vault <span className="chevron" />
            </a>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="small-container">
          <h2 style={{ maxWidth: 360 }}>
            Here&apos;s how card tokenization works
          </h2>

          <div className="tokenization-works">
            <div
              className={`single-tokenization-works ${
                tokenizationWorks === 0 ? 'active' : ''
              }`}>
              <img src="/img/token-vault/tokenization-1.jpg" alt="" />
            </div>
            <div
              className={`single-tokenization-works ${
                tokenizationWorks === 1 ? 'active' : ''
              }`}>
              <img src="/img/token-vault/tokenization-2.jpg" alt="" />
            </div>

            <div className="tokenization-works-controls">
              <button
                className={tokenizationWorks === 0 ? 'active' : ''}
                onClick={tokenizationSlideClick(0)}
                type="button">
                &nbsp;
              </button>
              <button
                className={tokenizationWorks === 1 ? 'active' : ''}
                onClick={tokenizationSlideClick(1)}
                type="button">
                &nbsp;
              </button>
            </div>
          </div>
        </div>
      </section> */}

      <section className="grey-bg section-padding why-token-main">
        <div className="small-container">
          <div className="token-faq-block">
            <h2 style={{ marginBottom: 40 }}>Why Token Vault?</h2>
            <div className="row flex-wrap align-normal">
              <div className="col col-6">
                <div className="img-text-block" style={{ maxWidth: '408px' }}>
                  <img
                    src="/img/icons/saved-card.svg"
                    width="44px"
                    height="44px"
                    alt="saved-card"
                    loading="lazy"
                  />
                  <h4 className="body-font">
                    A complete card tokenization solution
                  </h4>
                  <p>
                    Token Vault is a complete solution. It offers both saving
                    tokenized cards functionality and ability to process
                    payments using tokenized cards as a single solution so that
                    businesses do not have to look out for multiple payment
                    partners.
                  </p>
                </div>
              </div>
              <div className="col col-6">
                <div className="img-text-block" style={{ maxWidth: '485px' }}>
                  <img
                    src="/img/icons/instant-settlements.svg"
                    width="44px"
                    height="44px"
                    alt="instant-settlements"
                    loading="lazy"
                  />
                  <h4 className="body-font">
                    Cashfree Payments is a certified token requestor
                  </h4>
                  <p>
                    Cashfree Payments is a fully certified and compliant Token
                    requestor. We support tokenization of cards issued by all
                    leading card networks.
                  </p>
                </div>
              </div>
              <div className="col col-6">
                <div className="img-text-block" style={{ maxWidth: '485px' }}>
                  <img
                    src="/img/icons/card-pre-authorization.svg"
                    width="44px"
                    height="44px"
                    alt="card-pre-authorization"
                    loading="lazy"
                  />
                  <h4 className="body-font">Zero manual intervention</h4>
                  <p>
                    Businesses can integrate Token Vault API with their platform
                    with single integration. Once integrated, Cashfree Payments
                    takes care of both saved card function &amp; processing
                    payments.
                  </p>
                </div>
              </div>
              <div className="col col-6">
                <div className="img-text-block" style={{ maxWidth: '485px' }}>
                  <img
                    src="/img/icons/instant-refunds.svg"
                    width="44px"
                    height="44px"
                    alt="instant-refunds"
                    loading="lazy"
                  />
                  <h4 className="body-font">
                    Faster and secure checkout experience, guaranteed
                  </h4>
                  <p>
                    With Token Vault, you can offer repeat customers the option
                    to save card, customers can simply select the card and pay
                    directly just by entering the CVV of the card. Make checkout
                    faster and secure while staying RBI compliant.
                  </p>
                </div>
              </div>
            </div>

            <a
              className="button button-green"
              href="https://www.cashfree.com/contact-sales?source-action=tokenvault&action=Contact%20Sales&button-id=ContactSales_WhyTokenVault"
              style={{ margin: '5px' }}>
              Try Token Vault <span className="chevron" />
            </a>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="small-container">
          <div className="center">
            <h2 style={{ maxWidth: 700, margin: '0 auto 15px' }}>
              Learn from experts on how you can stay RBI compliant with card
              tokenization
            </h2>
            <p style={{ maxWidth: 640, margin: '0 auto ' }}>
              Have questions on the latest RBI guideline on Card on file
              Tokenization and how it will affect your customer transactions?
              Watch our detailed discussion or read through our comprehensive
              knowledgebase to learn more about card tokenization.
            </p>
            <div className="expert-main">
              <div className="row flex-wrap align-normal">
                <div className="col col-6">
                  <div className="learn-expert-block">
                    <h3 className="body-font">
                      Fireside Chat - Mastercard &amp; Cashfree Payments{' '}
                    </h3>
                    <p>
                      Watch this fireside chat to understand how Card
                      Tokenization can help your business stay RBI compliant.
                    </p>

                    <div className="featured-expert row row-no-margin">
                      <div className="ribbion-area">
                        <img src="/img/ribbion-2.svg" alt="" />
                        <span>FEATURING</span>
                      </div>
                      <img src="/img/token-vault/ravi-varma.png" alt="" />
                      <div>
                        <h4 className="body-font">Ravi Varma Datla</h4>
                        <p>VP Digital Products, MasterCard - South Asia</p>
                      </div>
                    </div>
                    <button
                      className="button button-green"
                      type="button"
                      onClick={OpenVideoModalToggle}>
                      Watch recorded session <span className="chevron" />
                    </button>
                  </div>
                </div>
                <div className="col col-6">
                  <div className="learn-expert-block">
                    <h3 className="body-font">
                      Get all your questions answered!
                    </h3>
                    <p>
                      Check-out our comprehensive guide on how Card-on-file
                      Tokenization works and how Cashfree Payments can help you
                      stay RBI-compliant!
                    </p>

                    <a
                      className="button button-green"
                      href="https://www.cashfree.com/help/questions/product-queries/token-vault">
                      Learn more <span className="chevron" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="token-faq-main no-support-faq">
        <div className="small-container">
          <FAQ
            data={[
              {
                id: 0,
                q: 'What is tokenization?',
                a: (
                  <>
                    <p>
                      Card Tokenization is the process of replacing sensitive
                      card information like card number, card expiry with a
                      cryptographically generated random string, referred to as
                      the card token. Once a card is tokenized, the generated
                      card token can be used for processing payments as a
                      substitute to the actual card details like card number and
                      card expiry and cvv thus eliminating the issue of loss of
                      sensitive card information while making card payments.
                    </p>
                  </>
                ),
              },
              {
                id: 1,
                q: 'What are the RBI guidelines on tokenization?',
                a: (
                  <>
                    <ul>
                      <li>
                        Neither payment aggregators, payment gateways nor
                        merchants cannot store card numbers on their servers
                        even if they are PCI/DSS compliant
                      </li>
                      <li>
                        Card networks and Issuing banks can only store card
                        numbers and offer token provisioning services to other
                        entities in the payment industry
                      </li>
                      <li>
                        <span className="bold">30th June 2022</span> is the
                        deadline for all entities to comply with the RBI
                        circular
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                id: 2,
                q: 'Why does a merchant need Token Vault by Cashfree Payments?',
                a: (
                  <>
                    <p>
                      Any merchant offering the save card feature to their
                      customers will have to do so by provisioning a token
                      instead of saving the actual card number. Token Vault will
                      help merchants to effortlessly migrate to the this RBI
                      compliance requirement.
                    </p>
                  </>
                ),
              },
              {
                id: 3,

                q: (
                  <>
                    If a merchant is PCI/DSS compliant and was saving cards on
                    their own server how can they meet RBI&apos;s compliance
                    requirement?
                  </>
                ),
                a: (
                  <>
                    <p>
                      PCI/DSS compliant merchants have to delete the already
                      saved cards with them as RBI does not allow bulk
                      tokenization of cards. * Merchants who were saving the
                      card number on their own servers, can simply integrate
                      with Token Vault APIs of Cashfree Payments and continue
                      processing saved cards after a one time authentication by
                      customers. In this case Cashfree Payments will act as
                      token requestor on behalf of the merchant. *
                      Alternatively, merchant can also integrate with individual
                      card schemes and become a token requestor themselves.
                    </p>
                  </>
                ),
              },
              {
                id: 4,

                q: (
                  <>
                    How will tokenization affect existing Cashfree Payments
                    merchants using Payment Gateway or Auto Collect?
                  </>
                ),
                a: (
                  <>
                    <p>
                      Cashfree Payments offers 2 types of checkout integrations{' '}
                      <br />
                      1) <span className="bold">Standard Checkout</span> &amp;
                      2) <span className="bold">Seamless Pro.</span>
                    </p>

                    <p>
                      A. Merchants using Standard Checkout integration do not
                      need to take any action. Cashfree Payments will
                      automatically enable the saved card feature along with the
                      ability to convert the card details ( as customers enter
                      the card) to unique tokens and then further process
                      tokenized cards received from card networks. Customers
                      having their cards already saved on the merchant site,
                      will have to enter the card details, and do a one time
                      re-authentication for the first transaction on or after
                      1st July 2022 to continue using the saved card feature.
                    </p>

                    <p>
                      B. Merchants using Seamless Pro integration will have to
                      update their APIs before 30th June 2022 to save and
                      process card transactions. Check out API documentation.
                      Once API has been updated, customers having their cards
                      already saved on the merchant site, will have to enter the
                      card details, and do a one time re-authentication for the
                      first transaction on or after 1st July 2022 to continue
                      using the saved card feature.
                    </p>
                  </>
                ),
              },
              {
                id: 5,

                q: (
                  <>
                    What all card details can the merchant save once with
                    tokenization in place?
                  </>
                ),
                a: (
                  <>
                    <p>
                      Merchants are allowed to store only the last 4 digits of
                      the actual card number, card scheme and issuing bank name.
                      They cannot store other details like card BIN, card expiry
                      or CVV.
                    </p>
                  </>
                ),
              },
              {
                id: 6,

                q: (
                  <>
                    Can a merchant retrieve the actual card number using the
                    card network token?
                  </>
                ),
                a: (
                  <>
                    <p>
                      No, merchants will not be able to get the actual card
                      number back from the tokenized cards. Only schemes and
                      issuing banks will be able to do so.
                    </p>
                  </>
                ),
              },
              {
                id: 7,

                q: (
                  <>
                    Can a merchant provision card network token without taking
                    consent from customer?
                  </>
                ),
                a: (
                  <>
                    <p>
                      No, explicit consent of customer is mandatory while
                      provisioning token for the card.
                    </p>
                  </>
                ),
              },
              {
                id: 8,

                q: (
                  <>
                    Can a merchant provision card network token without the
                    customer completing 2FA?
                  </>
                ),
                a: (
                  <>
                    <p>
                      If 2FA fails even after the customer had given consent to
                      tokenize the card, merchants will not be able to provision
                      token and save the card.
                    </p>
                  </>
                ),
              },

              {
                id: 9,

                q: (
                  <>
                    Does a merchant need to re-provision the already saved cards
                    on another payment aggregator or gateway if they wish to
                    migrate from Cashfree Payments?
                  </>
                ),
                a: (
                  <>
                    <p>
                      Yes, the token reference number of tokens provisioned
                      through Cashfree Payments will be saved with us only.
                      However, merchants can fetch the card network tokens from
                      us and use them for payment on any other payment
                      aggregator. It is not possible to migrate cards
                      provisioned through Cashfree Payments on another payment
                      gateway.
                    </p>
                  </>
                ),
              },
              {
                id: 10,

                q: (
                  <>
                    Does tokenization affect any other payment mode other than
                    cards? Which all card transactions are affected?
                  </>
                ),
                a: (
                  <>
                    <p>
                      No, tokenization is limited only for card payments. All
                      card payments like credit, debit, prepaid and corporate
                      credit cards are impacted.
                    </p>
                  </>
                ),
              },
              {
                id: 11,

                q: (
                  <>
                    Is there any impact of card tokenization on the card
                    payments where the customer enters complete card number?
                  </>
                ),
                a: (
                  <>
                    <p>
                      No, there is no impact as such on card payments where the
                      customer enters the complete card number details. Only in
                      cases where merchants or payment aggregators were saving
                      cards, card tokenization will come into effect.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </div>
      </section>

      <div className="footer-cta">
        <div className="small-container center">
          <h2>
            Offer saved card feature on your checkout pages and stay
            RBI-compliant with Token Vault
          </h2>

          <a
            className="button button-green"
            href="https://www.cashfree.com/contact-sales?source-action=tokenvault&action=Contact%20Sales&button-id=ContactSales_Footer"
            style={{ margin: '5px' }}>
            Contact Sales <span className="chevron" />
          </a>
        </div>
      </div>

      <div
        className={`row row-no-margin modal-video-main ${
          OpenVideoModal ? 'show-modal' : ''
        }`}>
        <div className="modal-video">
          <button
            type="button"
            onClick={OpenVideoModalToggle}
            className="close-video-modal">
            <Icon name="close" />
          </button>
          <iframe
            src={
              OpenVideoModal ? 'https://www.youtube.com/embed/EnMKlhpF-VA' : ''
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </Layout>
  );
};
export default CardTokenization;
